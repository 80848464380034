#about{
    overflow: hidden;
    width: 100vw;
    height: 30vw;
    /*background: rgb(40,40,40);*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.aboutTitle{
    font-size: 3rem;
    font-weight: 600;
    padding-top: 3rem;
    margin-bottom: 1.5rem;
}

.aboutDescription{
    font-weight: 300;
    font-size: 1rem;
    max-width: 50rem;
    padding: 0 2rem;
}

.bold{
    font-weight: bold;
}