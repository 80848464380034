#contactPage{
    min-height: calc(100vh - 10rem);
    width: 100vw;
    max-width: 60rem;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contactPageTitle{
    font-size: 3rem;
    margin-bottom: 1rem;
}

.contactDescription{
    padding: 1rem;
    font-size: medium;
    font-weight: 300;
}

.contactForm{
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;
}

.name, .email, .msg{
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background: rgb(40, 40, 40);
}

.submitBtn{
    background: white;
    border: none;
    margin: 2rem;
    padding: 0.75rem 3.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition-duration: 0.4s;
}

.submitBtn:hover{
    background: rgb(56, 110, 196);
    color: white;
    box-shadow: 0px 5px 5px 0px #1a1a1a;
}

.links{
    display: flex;
    flex-wrap: wrap;
}

.link{
    object-fit: cover;
    height: 2rem;
    width: 2rem;
    margin: 0 0.75rem;
    color: rgb(56, 110, 196);
    transition-duration: 0.4s;
}

.link:hover{
    color: white;
}