.breadcrumb {
    list-style: none;
    padding: 0;
    margin: 1em 0;
    margin-left: 20px;
    font-size: larger;
  }
  
  .breadcrumb-item {
    display: inline;
  }
  
  .breadcrumb-item + .breadcrumb-item::before {
    content: ">";
    margin: 0 0.5em;
  }

  .breadcrumb a{
    color: rgb(56, 110, 196);
    transition-duration: 0.4s;
  }

  .breadcrumb a:hover{
    color: rgb(63, 56, 196);
  }
  