html, body {
  height: 100%; /* Ensure the body takes full height */
  margin: 0; /* Remove default margin */
}

/* Background and main container */
.proj-page {
    background-color: rgb(30, 30, 30);
    color: #fff;
    margin: 40px 0;
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  /* Heading style */
  .proj-page h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Description text style */
  .proj-page p {
    font-size: 1.2rem;
    width: 60%;
    margin: 0 auto;
    line-height: 1.6;
    text-align: center;
  }

  .proj-page h4{
    font-weight: lighter;
    padding-top: 20px;
  }
  
  /* Screenshots section */
  .screenshots-section {
    margin-top: 50px;
    padding-bottom: 50px;
  }
  
  .screenshots-section h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Screenshots container */
  .screenshots {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .screenshot-box {
    width: 500px;
    height: 300px;
    background-color: #444;
    border-radius: 10px;
  }
  