.project-section {
    margin: 40px 0;
    color: white;
  }
  
  h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-left: 20px;
  }
  
/* Projects container styling */
.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Aligns items to the left */
  gap: 20px; /* Adds space between the cards */
  padding: 20px;
}

/* Individual project card styling */
.project-card {
  background-color: #f0f0f0;
  width: 225px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}

.project-card:hover{
  border: 2px solid white;
  box-shadow: rgb(0, 0, 0) 0px 0px 60px -12px; 
}

.proj-name{
  color: white;
  text-align: center;
  margin-top: 10px;
  max-width: 20ch;
}

  .project-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  