.navbar{
    background: rgb(30, 30, 30);
    height: 5rem;
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}

.logo{
    object-fit: cover;
    height: 3rem;
    width: 4rem;
}

.desktopMenuListItem{
    margin: 1rem;
    cursor: pointer;
}

.desktopMenuListItem:hover{
    color: rgb(155, 155, 155);
    padding-bottom: 0.5rem;
    border-bottom: 3px solid rgb(56, 110, 196);
}

.desktopMenuBtn{
    background: white;
    color: black;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    border-radius: 2rem;
    cursor: pointer;
    font-weight: bold;
    transition-duration: 0.4s;
}

.desktopMenuBtn:hover{
    background: rgb(56, 110, 196);
    color: white;
    box-shadow: 0px 5px 5px 0px #1a1a1a;
}

.desktopMenuImg {
    object-fit: cover;
    height: 1rem;
    width: 1rem;
    margin: 1rem 0.5rem;
}

.desktopMenuImg:hover {
    color: white;
}

.active{
    color: rgb(155, 155, 155);
    border-bottom: 3px solid rgb(56, 110, 196);
}

.mobMenu{
    display: none;
    object-fit: cover;
    height: 1.8rem;
    color: white;
}

.navMenu{
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 15rem;
    background: rgb(40,40,40);
    border-radius: 1rem;
}

.listItem{
    color: white;
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    background: rgb(30, 30, 30);
    border-radius: 0.5rem;
}

@media screen and (max-width:720px){
    .mobMenu{
        display: flex;
    }
    .desktopMenu{
        display: none;
    }
    .desktopMenuBtn{
        display: none;
    }   
}