#intro{
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.bg {
    position: absolute;
    top: -4rem;
    right: 0;
    z-index: -1;
    object-fit: cover;
    height: 80vh;
}

.introContent{
    height: 100vh;
    width: 100vh;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hello{
    font-size: 1.75rem;
    font-weight: 100;
}

.introName{
    color: rgb(56, 110, 196);
}

.introParagraph{
    font-size: medium;
    font-weight: 300;
    letter-spacing: .5px;
}

@media screen and (max-width: 840px){
    .bg{
        right: -10vw;
    }

    .introContent{
        font-size: 10vw;
    }

    .hello{
        font-size: 4.5vw;
    }
}   


@media screen and (max-width: 480px){
    .bg{
        right: -20vw;
    }

}   
