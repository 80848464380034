#works{
    margin: 0 auto;
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
}

.worksTitle{
    margin: 1rem 0;
    font-size: 3rem;
}

.worksDescription{
    font-weight: 300;
    font-size: 1rem;
    padding-bottom: 1rem;
    max-width: 45rem;
}

.worksImgs{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    max-width: 65rem;
    flex-wrap: wrap;
}

.worksImg{
    object-fit: cover;
    height: 20rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
    transition: all 0.4s ease-in-out;
}

.worksImg:hover{
    filter: brightness(70%);
}

#projButton{
    color: black;
    background-color: white;
    border: 2px solid white;
    border-radius: 15px;
    padding: 25px 50px;
    margin-top: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    font-size: larger;
    transition-duration: 0.4s;
}

#projButton:hover{
    background-color: rgb(56, 110, 196);
    color: white;
    box-shadow: 0px 5px 5px 0px #1a1a1a;
}

@media screen and (max-width:585){
    .worksImg{
        height: 48vw;
    }
}