.naavbaar{
    background: rgb(30, 30, 30);
    height: 5rem;
    max-width: 75rem;
    margin: 10px;
    padding: 1rem 2rem;
    top: 0;
    z-index: 3;
}

.logo{
    object-fit: cover;
    height: 3rem;
    width: 4rem;
}